export default {
    "搜尋名單": "搜寻名单",
    "搜尋範圍: 名字/郵件": "搜寻范围: 名字/邮件",
    "族群名稱": "族群名称",
    "請輸入族群名稱": "请输入族群名称",
    "所屬族群": "所属族群",
    "請選擇所屬族群": "请选择所属族群",
    "關閉": "关闭",
    "確定": "确定",
    "最少需要選擇幾位族群成員": "最少需要选择 %s 位族群成员",
    "最少需要選擇幾位託管人員": "最少需要选择 %s 位托管人员",
    "最多只能選擇幾位託管人員": "最多只能选择 %s 位托管人员",
    "尚未異動項目": "尚未异动项目",
    "成員種類1": "族群成员",
    "成員種類2": "托管人员",
    "可選擇幾個會友": "可选择 <b class=\"cgBase\">%s</b> 个会友",
    "請選擇加入的項目": "请选择加入的项目",
    "加入": "加入",
    "移除": "移除",
    "已選擇幾位族群成員": "已选择 <b class=\"cgBase\">%s</b> 位族群成员",
    "已選擇幾位託管人員": "已选择 <b class=\"cgBase\">%s</b> 位托管人员",
    "相關資訊": "相关资讯",
    "基本": "基本",
    "團報": "团报",
    "表單": "表单",
    "金流": "金流",
    "報名人員": "报名人员",
    "報名時間": "报名时间",
    "重設": "重设",
    "立即更新": "立即更新",
    "聚會開始時間": "聚会开始时间",
    "請選擇開始時間": "请选择开始时间",
    "首次聚會開始時間": "首次聚会开始时间",
    "聚會結束時間": "聚会结束时间",
    "請選擇結束時間": "请选择结束时间",
    "首次聚會結束時間": "首次聚会结束时间",
    "系統已經推播完成，無法編輯或刪除": "系统已经推播完成，无法编辑或删除",
    "系統已經正在推播，無法編輯或刪除": "系统已经正在推播，无法编辑或删除",
    "標題": "标题",
    "請輸入標題": "请输入标题",
    "上架時間": "上架时间",
    "下架時間": "下架时间",
    "外部活動連結": "外部活动连结",
    "請輸入外部活動連結": "请输入外部活动连结",
    "Youtube連結": "Youtube连结",
    "請輸入Youtube連結": "请输入Youtube连结",
    "Google雲端MP3": "Google云端MP3",
    "請輸入Google雲端MP3": "请输入Google云端MP3",
    "直播連結": "直播连结",
    "請輸入直播連結": "请输入直播连结",
    "外部網站連結": "外部网站连结",
    "請輸入外部網站連結": "请输入外部网站连结",
    "觀看": "观看",
    "外連": "外连",
    "留言": "留言",
    "推播": "推播",
    "排程": "排程",
    "類型": "类型",
    "置頂": "置顶",
    "封面圖": "封面图",
    "操作": "操作",
    "狀態": "状态",
    "建立時間": "建立时间",
    "如果不需要變更密碼，不用填寫": "如果不需要变更密码，不用填写",
    "變更密碼": "变更密码",
    "目前當前密碼": "目前当前密码",
    "請輸入目前當前密碼": "请输入目前当前密码",
    "登入密碼": "登入密码",
    "請輸入登入密碼": "请输入登入密码",
    "確認登入密碼": "确认登入密码",
    "請再次輸入登入密碼進行確認": "请再次输入登入密码进行确认",
    "頭像": "头像",
    "帳號": "帐号",
    "請輸入帳號": "请输入帐号",
    "名字": "名字",
    "請輸入名字": "请输入名字",
    "電子郵件": "电子邮件",
    "請輸入電子郵件": "请输入电子邮件",
    "電話": "电话",
    "請輸入聯絡電話": "请输入联络电话",
    "推播說明文字": "推播说明文字",
    "請輸入請輸入推播說明文字說明": "请输入推播说明文字",
    "地點": "地点",
    "請輸入地點": "请输入地点",
    "請選擇狀態": "请选择状态",
    "服事資訊": "服事资讯",
    "請輸入服事資訊": "请输入服事资讯",
}
